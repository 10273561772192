import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/notfoundpage.css"
import Logo from "../assets/icon/Bildmarke_Löffler_undMüller_weiß.png"

const NotFoundPage = () => {
    return (
        <div className='nf-container'>
            <img src={Logo} className='nf-logo' alt="" />
            <div className='acryl-container'>
                <h1 className='nf-heading'>404</h1>
                <p className='nf-paragraph'>Die angeforderte Seite existiert nicht.</p>
                <Link className='nf-link' to={'/'}>Kehren Sie zur Startseite zurück&nbsp;&#10138;</Link>
            </div>
        </div>
    );
};

export default NotFoundPage;
