/* eslint-disable no-restricted-globals */
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Datenschutz from './components/Datenschutz';
import Impressum from './components/Impressum';
import LandingPage from './components/LandingPage';
import ScrollToSection from './components/ScrollToSection';
import ScrollToTop from './ScrollToTop';
import NotFoundPage from './components/NotFoundPage';

function App() {
  return (
    <Router>
      <ScrollToTop></ScrollToTop>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <ScrollToSection />
              <LandingPage />
            </>
          }
        />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
