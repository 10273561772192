import React, { useEffect, useState } from "react";
import "../styles/contact.css";


const Kontakt = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => {
    document.title = 'Löffler-Müller | Kontaktformular';
  }, []);

  const [formAttempted, setFormAttempted] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const [agreement, setAgreement] = useState(false);

  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setAgreement(event.target.checked);
  }

  const getInputValidationClass = (name: keyof typeof formValues, attempted: boolean) => {
    if (!attempted) {
      return "";
    }

    if (formValues[name] === "") {
      return "invalid";
    }

    return "";
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormAttempted(true);

    if (
      formValues.name === "" ||
      formValues.email === "" ||
      formValues.message === ""
    ) {
      return;
    }

    const formData = new FormData(event.currentTarget);
    const name = formData.get("name") as string;
    const email = formData.get("email") as string;
    const phone = formData.get("phone") as string;
    const message = formData.get("message") as string;

    const response = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        message,
      }),
    });

    if (response.ok) {
      alert("E-Mail erfolgreich gesendet.");
    } else {
      const errorData = await response.json();
      if (errorData.message === "Die E-Mail-Domain existiert nicht.") {
        alert("Die E-Mail-Domain existiert nicht.");
      } else if (errorData.message === "Ungültige E-Mail-Adresse.") {
        alert("Ungültige E-Mail-Adresse.");
      } else {
        alert("Fehler beim Senden der E-Mail.");
      }
    }
  };

  return (
    <div className="contactPage">
      <section className="contact-form">
        <h1 className="form-heading">Wir haben Ihr Interesse geweckt?</h1>
        <form onSubmit={handleSubmit}>
          <div className="inputGroup">
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
              placeholder="Name"
              required
              className={getInputValidationClass("name", formAttempted)}
              autoComplete="off"
            />
          </div>
          <div className="inputGroup">
            <input
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              placeholder="E-Mail"
              required
              className={getInputValidationClass("email", formAttempted)}
              autoComplete="off"
            />
          </div>
          <div className="inputGroup">
            <input
              type="tel"
              name="phone"
              value={formValues.phone}
              onChange={handleInputChange}
              placeholder="Telefon (optional)"
              autoComplete="off"
            />
          </div>
          <div className="inputGroup">
            <textarea
              name="message"
              value={formValues.message}
              onChange={handleInputChange}
              placeholder="Ihr Anliegen"
              required
              className={getInputValidationClass("message", formAttempted)}
              autoComplete="off"
            />
          </div>
          <div className="agb-consent-holder">
            <input type="checkbox" name="agreement" onChange={handleChange} className="agb-consent-checkbox" />
            <p>Ich habe die Datenschutzerklärung zur Kenntnis genommen.
              Ich stimme zu, dass die von mir übermittelten Daten zur Kontaktaufnahme und für Rückfragen dauerhaft gespeichert werden. Diese Zustimmung kann ich jederzeit persönlich widerrufen.
            </p>
          </div>
          <button disabled={!agreement} className="send-button" type="submit">Nachricht senden</button>
        </form>
      </section>
    </div>
  );
};

export default Kontakt;
/* function getCheckboxvalue(event: Event | undefined) {
  throw new Error("Function not implemented.");
}

function setAgreement(checked: any) {
  throw new Error("Function not implemented.");
} */

