import React from "react";
import PrevButton from '../assets/icon/prev.png'
import NextButton from '../assets/icon/next.png'
import "../styles/teamSlider.css"

interface ArrowProps {
    onClick: () => void;
    // Weitere Props, die deine Pfeilkomponente benötigt
}

const CustomPrevArrow: React.FC<ArrowProps> = (props) => (
    <div className="custom-prev-arrow" onClick={props.onClick}>
        <img src={PrevButton} width={45} height={45} alt="" />
    </div>
);

const CustomNextArrow: React.FC<ArrowProps> = (props) => (
    <div className="custom-next-arrow" onClick={props.onClick}>
        <img src={NextButton} width={45} height={45} alt="" />
    </div>
);

export { CustomPrevArrow, CustomNextArrow };
