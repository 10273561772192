import { Link } from 'react-router-dom';
import '../styles/footer.css';
import Logo from "../assets/icon/LM-Logo.png";
import MailIcon from "../assets/icon/mail-contact-icon.png";
import LocationIcon from "../assets/icon/location-contact-icon.png";
import Phone from '../assets/icon/phone.png'
/* import Facebook from '../assets/icon/social-facebook.png'
import Instagram from '../assets/icon/social-instagram.png' */

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <section className='footer-cred-section'>
        <div className='footer-left-holder'>
          <img src={Logo} className='footer-l-m-logo' alt="" />
          <h1 className='footer-partner-text'>Wir sind Partner für die Komplettlösungen im Bereich Türen, Fenster und Tore. Mit uns realisieren Sie kleine und große Projekte im Objektbereich.</h1>
        </div>
        <div className='footer-right-holder'>
          <div className='cred-left-holder'>
            <div className='cred-content-holder'>
              <div>
                <img src={LocationIcon} width={25} height={25} alt="" />
              </div>
              <div className='cred-content'>
                <p>löffler + müller GmbH</p>
                <a href="https://maps.apple.com/?address=Humboldstraße%2018,%2008468%20Reichenbach" target="_blank" rel='noopener'>
                  Humboldstraße 18, 08468 Reichenbach&nbsp;&#10138;
                </a>
              </div>
            </div>
            <div className='cred-content-holder'>
              <div>
                <img src={MailIcon} width={25} height={25} alt="" />
              </div>
              <div className='cred-content'>
                <p>E-Mail</p>
                <a href="mailto:anfrage@loeffler-mueller.de">anfrage@loeffler-mueller.de&nbsp;&#10138;</a>
              </div>
            </div>
            <div className='cred-content-holder'>
              <div>
                <img src={Phone} width={25} height={25} alt="" />
              </div>
              <div className='cred-content'>
                <p>Telefon</p>
                <a href="tel:03661 7050171">03661 7050171&nbsp;&#10138;</a>
              </div>
            </div>
          </div>
          {/* <div className='cred-right-holder'>
            <p>Social Media</p>
            <div className='social-icon-holder'>
              <img src={Facebook} width={25} height={25} alt="" />
              <img src={Instagram} width={25} height={25} alt="" />
            </div>
          </div> */}
        </div>
      </section>
      <div className='footer-content-holder'>
        <div className='footer-content'>
          <p className="footer-text">
            &copy; {currentYear}, löffler + müller GmbH
          </p>
          <ul className='footer-link-list'>
            <li>
              <Link style={{ color: 'white' }} to="/impressum">Impressum</Link>
            </li>
            <div className='vert-divivider'></div>
            <li className='footer-last-link'>
              <Link style={{ color: 'white' }} to="/datenschutz">Datenschutzerklärung</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;