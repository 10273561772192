import React from 'react';

import Footer from './Footer';
import HomeHeader from "./HomeHeader";
import "../styles/HomeLayout.css";

interface HomeLayoutProps {
    children?: React.ReactNode;
}

const HomeLayout: React.FC<HomeLayoutProps> = ({ children }) => {
    return (
        <div className="default-layout">
            <HomeHeader />
            <main className="default-layout__content">
                {children} {/* Hier wird der Inhalt der jeweiligen Seite gerendert */}
            </main>
            <Footer />
        </div>
    );
}

export default HomeLayout;
