import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../styles/serviceSlider.css"
import Rohr from "../assets/icon/rohrramentueren.png";
import Schließanlagen from '../assets/icon/Schließanlagen.png'
import ServiceSliderDots from './ServiceSliderDots';
import Tore from '../assets/icon/Tore.png'
import Beschlag from '../assets/icon/Beschlag.png'
import Holz from '../assets/icon/Holztüren.png'
import Stahl from '../assets/icon/Stahltüren.png'
import Zargen from '../assets/icon/Zargen.png'
import { CustomNextArrow, CustomPrevArrow } from './SlickNavArrow';

function ServiceSlider() {
    const servicesData = [
        {
            id: 1,
            title: "Rohrrahmentüren",
            description: "Raumerlebnisse aus Glas und Metall schaffen diese Türelemente. Gleichzeitig bieten sie die höchsten Normen des Brandschutzes in den entsprechenden Gebäudeklassen.",
            icon: Rohr,
        },
        {
            id: 2,
            title: "Zargen",
            description: "Türen oder Fenster jeglicher Art und Güte funktionieren eben nicht ohne die passende Zarge. Zu allen erforderlichen Systemen bieten wir die passenden Zargen an.", // Beispielwert für Marktplatz-Bestellnr. (kann hier dynamisch angepasst werden)
            icon: Zargen,
        },
        {
            id: 3,
            title: "Holztüren",
            description: "Holztüren setzen in jeden Wohn- und Geschäftsraum besondere Akzente. Durch die ganz individuellen Maserungen sind sie Unikate und stehen für ein besonderes Raumklima.", // Beispielwert für Marktplatz-Bestellnr. (kann hier dynamisch angepasst werden)
            icon: Holz,
        },
        {
            id: 4,
            title: "Stahltüren",
            description: "Stahltüren werden mit den härtesten Anforderungen konfrontiert und halten diesen Stand. Robust und solide ausgestattet, trotzen sie dem harten Türalltag.", // Beispielwert für Marktplatz-Bestellnr. (kann hier dynamisch angepasst werden)
            icon: Stahl,
        },
        {
            id: 5,
            title: "Beschläge",
            description: "Beschläge entscheiden nicht nur über die Optik einer Türanlage, sie müssen auch x-fache Bedienzyklen überstehen, ohne ihre technischen und optischen Spezifikationen zu verlieren.", // Beispielwert für Marktplatz-Bestellnr. (kann hier dynamisch angepasst werden)
            icon: Beschlag,
        },
        {
            id: 6,
            title: "Schließanlagen",
            description: "Moderne Schließanlagen sichern die Privatsphäre, schützen und bieten die Möglichkeit auf die verschiedensten Sicherheitsstandards zu reagieren.", // Beispielwert für Marktplatz-Bestellnr. (kann hier dynamisch angepasst werden)
            icon: Schließanlagen,
        },
        {
            id: 7,
            title: "Toranlagen",
            description: "Tore in den verschiedensten Einsatzbereichen tragen neben der geschaffenen Sicherheit auch zum optischen Erscheinungsbild einer Wohn- und Gewerbeanlage bei.", // Beispielwert für Marktplatz-Bestellnr. (kann hier dynamisch angepasst werden)
            icon: Tore,
        },
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef<Slider | null>(null);
    const slidesToScroll = servicesData?.length;

    const settings = {
        dots: false,
        infinite: true, // Schalten Sie die Endlosschleife aus
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '0',
        focusOnSelect: false,
        prevArrow: <CustomPrevArrow onClick={function (): void {
            throw new Error('Function not implemented.');
        }} />, // Hier kannst du deine eigenen Pfeilkomponenten erstellen
        nextArrow: <CustomNextArrow onClick={function (): void {
            throw new Error('Function not implemented.');
        }} />,
        afterChange: (currentIndex: number) => setCurrentIndex(currentIndex),
        draggable: false,
        swipe: false,
        responsive: [
            {
                breakpoint: 1560, // Maximalbreite
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    // ...weitere Einstellungen für diese Bildschirmgröße...
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                    swipe: true,
                    // ...weitere Einstellungen für diese Bildschirmgröße...
                }
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    draggable: true,
                    swipe: true,
                    // ...weitere Einstellungen für diese Bildschirmgröße...
                }
            }
        ]
    };

    const goToSlide = (currentIndex: number) => {
        // Prüfen Sie, ob der sliderRef vorhanden ist und die Methode slickGoTo unterstützt
        if (sliderRef.current && sliderRef.current.slickGoTo) {
            // Rufen Sie die Methode slickGoTo auf, um zum gewünschten Index zu springen
            sliderRef.current.slickGoTo(currentIndex);
        }
    };

    return (
        <div className="service-slider-container">
            <Slider className='sliderDesktop' {...settings} ref={sliderRef}>
                {servicesData.map((service, index) => (
                    <div className="service-slide" key={index}>
                        <div className='list-holder'>
                            <div className="service-card-container">
                                <img src={service.icon} width={85} height={85} alt="" />
                                <h1 className='service-title'>{service.title}</h1>
                                <p className='service-content'>{service.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className='team-slick-slider-bottom-navigation-holder'>
                <ServiceSliderDots
                    slideCount={slidesToScroll}
                    currentIndex={currentIndex}
                    goToSlide={goToSlide}
                />
            </div>
        </div>
    );
}

export default ServiceSlider;