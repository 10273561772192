import { Link } from "react-router-dom";
import "../styles/dsbvo.css"
import ListDot from "../assets/icon/list-dot.png"
import { useEffect } from "react";
import DifferentLayout from "./DifferentLayout";

const Datenschutz = () => {

    useEffect(() => {
        document.title = 'Löffler-Müller | Datenschutzerklärung';
    }, []);

    return (
        <DifferentLayout>
            <div className="dsbvo-page-holder">
                <div className="dsbvo-content-container">
                    <div className="dsbvo-heading-holder">
                        <h1>Datenschutz</h1>
                    </div>
                    <div className="dsbvo-welcome-holder">
                        <h3>Danke für Ihr Interesse an unserem Unternehmen.</h3>
                        <h3>Wir nehmen Datenschutz sehr ernst.</h3>
                        <p>Sie können unsere Webseite grundsätzlich ohne jede Angabe personenbezogener Daten nutzen. Sofern eine betroffene Person Leistungen unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte, so könnte eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, dann holen wir stets eine Einwilligung der betroffenen Person ein.
                            Die Verarbeitung personenbezogener Daten (z.B. Name, Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person), erfolgt stets im Einklang mit der Datenschutzgrundverordnung (DSGVO) und in Übereinstimmung mit den für uns geltenden landesspezifischen Datenschutzbestimmungen.
                            Mit der nachfolgenden Datenschutzerklärung möchten wir die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren.
                            Ebenfalls werden betroffene Personen durch diese Datenschutzerklärung über die ihnen zustehenden Rechte informiert.
                            Als für die Verarbeitung Verantwortliche haben wir zahlreiche technische und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über unsere Webseite verarbeiteten personenbezogenen Daten sicherzustellen. Datenübertragungen über das Internet können aber grundsätzlich Sicherheitslücken enthalten. Es kann somit ein 100%iger Schutz nicht gewährleistet werden, daher kann uns jede betroffene Person, natürlich auch alternativ -z.B. per Telefon-, personenbezogener Daten übermitteln.
                        </p>
                    </div>
                    <div className="ordered-list-holder">
                        <div className="list-count-holder">
                            <h3>1.</h3>
                        </div>
                        <div className="wording-content-holder">
                            <h3>Begriffsbestimmungen</h3>
                            <p>Diese Datenschutzerklärung beruht auf den Begriffsbestimmungen, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der DSGVO benutzt wurden (Art. 4 DSGVO). Diese Datenschutzerklärung soll für jede Person sowohl einfach lesbar als auch leicht verständlich sein. Um dies sicherzustellen, möchten wir zunächst die verwendeten Begrifflichkeiten erläutern. In dieser Datenschutzerklärung werden unter anderem diese Begriffsbestimmungen verwendet:
                            </p>
                            <br />
                            <div className="wording-list-holder">
                                <p>
                                    <span className="wording-span">* „personenbezogene Daten“</span> alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Onlinekennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
                                </p>
                                <p>
                                    <span className="wording-span">* „betroffene Person“</span> jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten, von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
                                </p>
                                <p>
                                    <span className="wording-span">* „Verarbeitung“</span> jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe, im Zusammenhang, mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung;
                                </p>
                                <p>
                                    <span className="wording-span">* „Einschränkung der Verarbeitung“</span> die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken;
                                </p>
                                <p>
                                    <span className="wording-span">* „Profiling“</span> jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bzgl. Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen;
                                </p>
                                <p>
                                    <span className="wording-span">* „Verantwortlicher“</span> die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung personenbezogener Daten entscheidet; sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht, oder das Recht der Mitgliedstaaten vorgegeben, so können der Verantwortliche bzw. die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedsstaaten vorgesehen werden;
                                </p>
                                <p>
                                    <span className="wording-span">* „Empfänger“</span> eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, denen personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedsstaaten möglicherweise personenbezogener Daten erhalten, gelten jedoch nicht als Empfänger; die Verarbeitung dieser Daten durch die genannten Behörden erfolgt im Einklang mit den geltenden Datenschutzvorschriften, gem. den Zwecken der Verarbeitung;
                                </p>
                                <p>
                                    <span className="wording-span">* „Dritter“</span> eine natürliche oder juristische Person, Behörde, Einrichtung oder andereStelle, außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten;
                                </p>
                                <p>
                                    <span className="wording-span">* „Einwilligung“</span> der betroffenen Person jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebener Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogener Daten einverstanden ist.
                                </p>
                            </div>
                        </div>
                        <div className="list-count-holder">
                            <h3>2.</h3>
                        </div>
                        <div>
                            <h3>Name und Kontaktdaten des für die Verarbeitung des Verantwortlichen</h3>
                            <p>
                                Diese Datenschutzhinweise gelten für die Datenverarbeitung durch:
                                Löffler+müller GmbH
                                Humboldstraße 18
                                08468, Reichenbach

                            </p>
                        </div>
                        <div className="list-count-holder">
                            <h3>3.</h3>
                        </div>
                        <div>
                            <h3>Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h3>
                            <p><span className="data-span">a) Beim Besuch der Website</span><br />
                                Sie können unsere Webseite grundsätzlich ohne Offenlegung Ihrer Identität nutzen. Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem s.g. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
                                * IP-Adresse des anfragenden Rechners,
                                * Datum und Uhrzeit des Zugriffs,
                                * Name und URL der abgerufenen Datei,
                                * Website, von der aus der Zugriff erfolgt (Referrer-URL),
                                * verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres
                                Access-Providers.
                                Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
                                * Gewährleistung eines reibungslosen Verbindungsaufbaus der Website, * Gewährleistung einer komfortablen Nutzung unserer Website,
                                * Auswertung der Systemsicherheit und -stabilität sowie
                                * zu weiteren administrativen Zwecken.
                                Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. F DSGVO. Unser berechtigtes Interesse folgt aus den oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen. <br />
                                <br /><span className="data-span">b) Bei Nutzung unseres Kontaktformulars</span><br />
                                Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf unserer Webseite bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden. Es unterliegt Ihrer freien Entscheidung, ob Sie diese Daten im Rahmen des Kontaktformulars eingegeben möchten.
                                Die Datenverarbeitung zum Zweck der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.
                                Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht.
                            </p>
                        </div>
                        <div className="list-count-holder">
                            <h3>4.</h3>
                        </div>
                        <div>
                            <h3>Weitergabe von Daten</h3>
                            <p>Eine Weitergabe Ihrer personenbezogenen Daten von uns an Dritte erfolgt ausschließlich an die im Rahmen der Vertragsabwicklung beteiligten Dienstleistungspartner, wie z.B. das mit der Lieferung beauftragte Logistik-Unternehmen und das mit Zahlungsangelegenheiten beauftragte Kreditinstitut. In den Fällen der Weitergabe Ihrer personenbezogenen Daten an Dritte beschränkt sich der Umfang der übermittelten Daten jedoch auf das erforderliche Minimum.
                                Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den vorgenannten Zwecken findet nicht statt.
                                Wir geben Ihre persönlichen Daten auch nur dann an Dritte weiter, wenn:
                                <div className="data-list-holder">
                                    <div className="data-list-row-holder">
                                        <div>
                                            <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                        </div>
                                        <p>
                                            Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
                                        </p>
                                    </div>
                                    <div className="data-list-row-holder">
                                        <div>
                                            <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                        </div>
                                        <p>
                                            die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,
                                        </p>
                                    </div>
                                    <div className="data-list-row-holder">
                                        <div>
                                            <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                        </div>
                                        <p>
                                            für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie
                                        </p>
                                    </div>
                                    <div className="data-list-row-holder">
                                        <div>
                                            <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                        </div>
                                        <p>
                                            dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
                                        </p>
                                    </div>
                                </div>
                                Sämtliche Zahlungsdaten sowie Daten zu eventuell auftretenden Rückbelastungen werden nur so lang gespeichert, wie sie für die Zahlungsabwicklung (einschließlich der Bearbeitung von möglichen Rücklastschriften und dem Forderungseinzug) und zur Missbrauchsbekämpfung benötigt werden. In der Regel werden die Daten spätestens 13 Monate nach ihrer Erhebung gelöscht.
                                Darüber hinaus kann eine weitere Speicherung erfolgen, sofern und solange dies zur Einhaltung einer gesetzlichen Aufbewahrungsfrist oder zur Verfolgung eines konkreten Missbrauchsfalls erforderlich ist. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1f) Datenschutz-Grundverordnung.
                                Sie können Auskunft und ggf. Berichtigung oder Löschung sowie die Einschränkung der Verarbeitung Ihrer Daten verlangen und/oder ggf. der Verarbeitung Ihrer Daten widersprechen. Bei Fragen zur Datenverarbeitung durch Concardis oder zur Geltendmachung Ihrer vorgenannten Rechte können Sie sich an den Datenschutzbeauftragten wenden, den Sie unter der angegebenen Adresse oder per E-Mail unter: Datenschutzbeauftragter@concardis.com erreichen.
                                Des Weiteren haben Sie das Recht, sich bei einer Aufsichtsbehörde (in Deutschland bei den Landesdatenschutzbeauftragten) zu beschweren. Wir weisen darauf hin, dass die Bereitstellung der Zahlungsdaten weder gesetzlich noch vertraglich vorgeschrieben ist. Wenn Sie Ihre Zahlungsdaten nicht bereitstellen wollen, können Sie ein anderes Zahlungsverfahren (z. B. Barzahlung) verwenden.
                                Im Rahmen des Bestellprozesses wird eine Einwilligung von Ihnen zur Weitergabe Ihrer Daten an Dritte eingeholt.
                            </p>
                        </div>
                        <div className="list-count-holder">
                            <h3>5.</h3>
                        </div>
                        <div>
                            <h3>Links auf Web-Sites Dritter</h3>
                            <p>Die auf unserer Webseite veröffentlichten Links werden von uns mit größtmöglicher Sorgfalt recherchiert und zusammengestellt. Wir haben jedoch keinen Einfluss auf die aktuelle und zukünftige Gestaltung und Inhalte der verlinkten Seiten. Wir sind nicht für den Inhalt der verknüpften Seiten verantwortlich und machen uns den Inhalt dieser Seiten ausdrücklich nicht zu eigen. Für illegale, fehlerhafte oder unvollständige Inhalte sowie für Schäden, die durch die Nutzung oder Nichtnutzung der Informationen entstehen, haftet allein der Anbieter der Website, auf die verwiesen wurde. Die Haftung desjenigen, der lediglich auf die Veröffentlichung durch einen Link hinweist, ist ausgeschlossen. Für fremde Hinweise sind wir nur dann verantwortlich, wenn wir von ihnen, d.h. auch von einem eventuellen rechtswidrigen bzw. strafbaren Inhalt, positive Kenntnis haben und es uns technisch möglich und zumutbar ist, deren Nutzung zu verhindern.</p>
                        </div>
                        <div className="list-count-holder">
                            <h3>6.</h3>
                        </div>
                        <div>
                            <h3>Betroffenenrechte</h3>
                            <p>Sie haben das Recht:
                                <ol className="right-ol-holder">
                                    <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                    <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</li>
                                    <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                    <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li>
                                    <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                    <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
                                    <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                    <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art.21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
                                    <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                    <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</li>
                                    <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                    <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und</li>
                                    <img className="list-dot" src={ListDot} width={10} height={10} alt="" />
                                    <li>gemäß Art. 77 DSGVO, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Firmensitzes wenden.</li>
                                </ol>
                            </p>
                        </div>
                        <div className="list-count-holder">
                            <h3>7.</h3>
                        </div>
                        <div>
                            <h3>Widerspruchsrecht</h3>
                            <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.
                                Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an: <Link to={"mailto:info@bauzentrum-loeffler"} className="revoke-mail-link">info@bauzentrum-loeffler</Link>.de
                            </p>
                        </div>
                        <div className="list-count-holder">
                            <h3>7.</h3>
                        </div>
                        <div>
                            <h3>Datensicherheit</h3>
                            <p>
                                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                            </p>
                        </div>
                        <div className="list-count-holder">
                            <h3>8.</h3>
                        </div>
                        <div>
                            <h3>Aktualität und Änderung dieser Datenschutzerklärung</h3>
                            <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand April 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </DifferentLayout>
    );
};

export default Datenschutz;