import { Link } from "react-router-dom";
import "../styles/impressum.css"
import { useEffect } from "react";
import DifferentLayout from "./DifferentLayout";

const Impressum = () => {

    useEffect(() => {
        document.title = 'Löffler-Müller | Impressum';
    }, []);

    return (
        <DifferentLayout>
            <div className="impressum-page-holder">
                <div className="impressum-content-container">
                    <div className="page-heading">
                        <h1>Impressum</h1>
                    </div>
                    <div className="company-credentials-holder">
                        <p>löffler+müller GmbH</p>
                        <p>Humboldstr. 18</p>
                        <p>08468, Reichenbach</p>
                    </div>
                    <div className="register-content-holder">
                        <h3>Registereintrag</h3>
                        <p>HRB 35886 Amtsgericht Chemnitz</p>
                        <p>Ust-ID: DE362 145 651</p>
                    </div>
                    <div className="lead-holder">
                        <h3>Geschäftsführer</h3>
                        <p>Markus Müller</p>
                        <p>Martin Löffler</p>
                    </div>
                    <div className="content-response-holder">
                        <h3>Inhaltliche Verantwortung</h3>
                        <p>Markus Müller</p>
                        <p>Martin Löffler</p>
                        <p>Kontakt siehe oben</p>
                    </div>
                    <div className="content-origin-holder">
                        <h3>Bildnachweis</h3>
                        <p>Martin Löffler</p>
                    </div>
                    <div className="powered-by-holder">
                        <h3>Webdesign & Programmierung</h3>
                        <Link to="https://nodiatech.de/" target="_SEJ" rel="noopener" className="powered-by-link">NodiaTech UG (haftungsbeschränkt)</Link>
                    </div>
                </div>
            </div>
        </DifferentLayout>
    );
};

export default Impressum;