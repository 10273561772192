/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState, } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../styles/teamSlider.css"
import Slider from 'react-slick';
import Marion from "../assets/img/Marion Hensel.jpg"
import Martin from '../assets/img/Martin Löffler.jpg'
import Markus from '../assets/img/Markus Müller.jpg'
import Uwe from '../assets/img/Uwe Hallas.jpg'
import Hannes from '../assets/img/Hannes Haenel.jpg'
import Marcel from '../assets/img/Marcel Hincu.jpg'
import TeamSliderDots from './TeamSliderDots';
import PauseButton from '../assets/icon/PauseButton.png'
import PlayButton from '../assets/icon/PlayButton.png'
import { CustomNextArrow, CustomPrevArrow } from './SlickNavArrow';

function TeamSlider() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef<Slider | null>(null);// Zustand, um Autoplay ein- und auszuschalten
    const [isPlaying, setIsPlaying] = useState(true);

    const togglePlayPause = () => {
        if (isPlaying) {
            sliderRef.current?.slickPause(); // Pausiere den Slider
        } else {
            sliderRef.current?.slickPlay(); // Starte den Slider
        }
        setIsPlaying((prevState) => !prevState);
    };

    const settings = {
        dots: false,
        infinite: true, // Schalten Sie die Endlosschleife aus
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        focusOnSelect: true,
        autoplay: false,
        prevArrow: <CustomPrevArrow onClick={function (): void {
            throw new Error('Function not implemented.');
        }} />, // Hier kannst du deine eigenen Pfeilkomponenten erstellen
        nextArrow: <CustomNextArrow onClick={function (): void {
            throw new Error('Function not implemented.');
        }} />,
        afterChange: (index: number) => setCurrentIndex(index),
    };

    const teamMembers = [
        {
            author: "Marion Hensel",
            value: "Marion ist ab dem ersten Tag unsere Powerfrau hinter den Kulissen. Sie kalkuliert Elemente mit Hingabe und größtmöglicher Effizienz für unsere Kunden und deren Projekte.",
            icon: Marion,
        },
        {
            author: "Hannes Hänel",
            value: "Hannes ist unser jüngster Projektleiter. Er arbeitet mit größtem Engagement an dem Erfolg eines jeden Projekts, vom ersten Aufmaß bis hin zur Projektabnahme.",
            icon: Hannes,
        },
        {
            author: "Uwe Hallas",
            value: "Uwe ist das Urgestein in unserem Kernteam. Er lebt Türen, Fenster und Tore. Durch seine Expertise und das dahinterstehende Team an Monteuren gelingen uns nahezu alle Anforderungen.",
            icon: Uwe,
        },
        {
            author: "Markus Müller",
            value: "Markus ist Gründer und Geschäftsführer unserer Firma. Er leitet unsere Teams in allen projektführenden Phasen.",
            icon: Markus,
        },
        {
            author: "Martin Löffler",
            value: "Martin ist Gründer und Geschäftsführer unserer Firma. Er ist der Kopf in allen vertrieblichen und projektanbahnenden Phasen.",
            icon: Martin,
        },
        {
            author: "Marcel Hincu",
            value: "Marcel gehört mit seinem Team neben Uwe zu unseren Monteuren. Er und sein Team montieren Elemente in Qualität und Quantität in Geschwindigkeiten, welche uns regelmäßig verblüffen.",
            icon: Marcel,
        },
    ];

    const goToSlide = (index: number) => {
        // Prüfen Sie, ob der sliderRef vorhanden ist und die Methode slickGoTo unterstützt
        if (sliderRef.current && sliderRef.current.slickGoTo) {
            // Rufen Sie die Methode slickGoTo auf, um zum gewünschten Index zu springen
            sliderRef.current.slickGoTo(index);
        }
    };

    useEffect(() => {
        // Fügen Sie hier ggf. Cleanup-Code hinzu, wenn die Komponente entladen wird
        return () => {
            // z.B. Clear Timer oder ähnliches
        };
    }, []);

    return (
        <div className="team-slider-container">
            <Slider {...settings} ref={sliderRef}>
                {teamMembers.map((team, index) => (
                    <div key={index} className={`team-card ${currentIndex === index ? 'active' : ''}`}>
                        <div className='holder'>
                            <div className="team-content">
                                <h2 className="team-name">{team.author}</h2>
                                <p className="team-description">{team.value}</p>
                            </div>
                            <div className='team-image-slider'>
                                {index > 0 && (
                                    <img className='team-outside-image' src={teamMembers[index - 1].icon} />
                                )}
                                <img className='team-center-image' src={team.icon} />
                                {index < teamMembers.length - 1 && (
                                    <img className='team-outside-image' src={teamMembers[index + 1].icon} />
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className='team-slick-slider-bottom-navigation-holder'>
                <TeamSliderDots
                    slideCount={teamMembers.length}
                    currentIndex={currentIndex}
                    goToSlide={goToSlide}
                />
            </div>
        </div>
    );
}

export default TeamSlider;
