import React from 'react';

import Footer from './Footer';
import "../styles/DifferentLayout.css";
import DifferentHeader from './DifferentHeader';

interface DifferentLayoutProps {
    children?: React.ReactNode;
}

const DifferentLayout: React.FC<DifferentLayoutProps> = ({ children }) => {
    return (
        <div className="default-layout">
            <DifferentHeader />
            <main className="default-layout__content">
                {children} {/* Hier wird der Inhalt der jeweiligen Seite gerendert */}
            </main>
            <Footer />
        </div>
    );
}

export default DifferentLayout;
