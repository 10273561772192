import React, { useEffect } from 'react';

const ScrollToSection = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, []);

    return null;
};

export default ScrollToSection;
