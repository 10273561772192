import React from 'react';

import "../styles/teamNav.css"

interface ServiceSliderDotsProps {
  slideCount: number;
  currentIndex: number;
  goToSlide: (index: number) => void;
}

function TeamSliderDots({
  slideCount,
  currentIndex,
  goToSlide,
}: ServiceSliderDotsProps) {
  const dots = [];

  for (let i = 0; i < slideCount; i++) {
    dots.push(
      <button
        key={i}
        className={`service-dot ${currentIndex === i ? 'active' : ''}`}
        onClick={() => goToSlide(i)}
      >
        <div className={`dot ${currentIndex === i ? 'active' : ''}`}></div>
      </button>
    );
  }

  return (
    <div className="nav-dots-container">
      {dots}
    </div>
  );
}

export default TeamSliderDots;
